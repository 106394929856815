import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Chip } from '@mui/material';
import { CellRisk } from '../../../common/list/cells/CellRisk';
import { List } from '../../../common/list/List';
import { IFeedback } from '../../../EmployeesPage/api/apiSlice.types';
import { CellButtonList } from '../../../common/list/cells/CellButtonList';
import { CellButton } from '../../../common/list/cells/CellButton';
import { useEditFeedbackMutation } from '../../../EmployeesPage/api/apiSlice';

interface IProps {
  items: Array<IFeedback>;
  isFetching: boolean;
  onSortChange: (column: any, direction: string) => void;
  risks?: Array<{ id: string; name: string }>;
  canChangeEntity: boolean;
}

// todo: change column width. right now it's unreasonably small for some columns
const columnsToDisplay = [
  {
    Name: 'Feedback Type',
    Id: 'feedback_type',
    className: 'medium-col',
  },
  {
    Name: 'Employee',
    Id: 'employee',
    className: 'medium-col',
    isObject: true,
  },
  {
    Name: 'Position',
    Id: 'position',
    valueGetter: (row: IFeedback) => row.employee?.position,
    className: 'medium-col',
  },
  {
    Name: 'Manager',
    Id: 'manager',
    className: 'medium-col',
    valueGetter: (row: IFeedback) => row.employee?.manager?.name,
    isObject: true,
  },
  {
    Name: 'Updated By',
    Id: 'modified_by',
    className: 'medium-col',
    isObject: true,
  },
  {
    Name: 'Feedback date',
    Id: 'feedback_date',
    className: 'medium-col',
  },
  {
    Name: 'Risk level',
    Id: 'risk_level',
    className: 'medium-col',
  },
  {
    Name: 'Non actual',
    Id: 'non_actual',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: '',
    Id: 'action',
    className: 'action-col-2',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
];

export const Table = ({
  items,
  onSortChange,
  risks,
  isFetching,
  canChangeEntity,
}: IProps) => {
  const [column, setColumn] = useState('' as string);
  const [direction, setDirection] = useState('' as string);

  const [editFeedback] = useEditFeedbackMutation();

  const renderRow = (defaultRowRender: React.ReactNode, rowItem: IFeedback) => (
    <Box
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={`/employees/${rowItem.employee.id}/feedbacks/${rowItem.id}`}
      state={{
        pathname: '/feedbacks',
      }}
    >
      {defaultRowRender}
    </Box>
  );

  const onSortClick = (column: string, sortDirection: string) => {
    setColumn(column);
    setDirection(sortDirection);
    onSortChange(column, sortDirection);
  };

  return (
    <List
      columnsToDisplay={columnsToDisplay}
      config={{
        risk_level: {
          risks,
          cell: (props: any) =>
            props?.item?.is_external ? (
              <Box className='c-list__item-column medium-col'>
                <Chip label='External' variant='outlined' size='small' />
              </Box>
            ) : (
              <CellRisk {...props} />
            ),
        },
        action: {
          cell: CellButtonList,
          isHaveRights: canChangeEntity,
          render: (item: any) => [
            <CellButton
              canClick={!item.item.non_actual}
              onClick={async () => {
                await editFeedback({
                  id: Number(item.item.employee.id),
                  feedback: { id: item.item.id, non_actual: true },
                }).unwrap();
              }}
              title='Mark as non actual'
              item={item}
              icon='pencil'
              key={item.item.id + Math.random()}
            />,
          ],
        },
        non_actual: {
          isHaveRights: canChangeEntity,
          cell: (props: any) => (
            <Box width={200}>
              {props?.item?.non_actual && (
                <Chip label='Non actual' variant='outlined' size='small' />
              )}
            </Box>
          ),
        },
      }}
      items={items || []}
      isFetching={isFetching}
      sortColumnName={column}
      sortDirection={direction}
      setSortSettings={onSortClick}
      renderRow={renderRow}
    />
  );
};
