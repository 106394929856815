import {
  useGetExportLocationFileMutation,
  useGetLocationReportMutation,
} from '../../EmployeesPage/api/apiSlice';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { ChartAppLocation } from './ChartAppLocation';
import { ILocationReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import Export from '../../common/Export';
import Filters from '../Filters/Filters';
import LocationTable from './LocationTable';

export const LocationReport = () => {
  const [getReport] = useGetLocationReportMutation();
  const [downloadFile] = useGetExportLocationFileMutation();
  const [data, setData] = useState<ILocationReportResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [allFilters, setAllFilters] = useState({});

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Location Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setAllFilters={setAllFilters}
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
      />

      {!!data?.length && (
        <Stack direction={'row'} spacing={2}>
          <Box width={'50%'} pt={3}>
            <ChartAppLocation data={data} />
          </Box>
          <Box width={'50%'} pt={3}>
            <LocationTable rows={data} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
