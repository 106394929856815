import {
  useGetExportAttritionFileMutation,
  useGetAttritionReportMutation,
  useGetAttritionReportTypesQuery,
} from '../../EmployeesPage/api/apiSlice';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { ChartAppAttrition } from './ChartAppAttrition';
import {
  IAttritionReportResponse,
  AttritionReportType,
} from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import Filters from '../Filters/Filters';
import Export from '../../common/Export';
import AttritionTable from './Table';

export const AttritionReport = () => {
  const [getReport] = useGetAttritionReportMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAttritionReportResponse>({});
  const [attritionReportType, setAttritionReportType] =
    useState<AttritionReportType>('dismissed_reason');
  const [allFilters, setAllFilters] = useState({});
  const [downloadFile] = useGetExportAttritionFileMutation();

  const { data: attritionReportTypes } = useGetAttritionReportTypesQuery();

  const attritionReportValues = useMemo(() => {
    if (attritionReportTypes) {
      return Object.entries(attritionReportTypes);
    }
    return [];
  }, [attritionReportTypes]);

  const handleChangeAttritionReportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttritionReportType(event.target.value as AttritionReportType);
  };
  const { reportData, totalEmployees } = Object.keys(data).reduce(
    (acc, month) => {
      const totalEmployees = 'Total active employees';
      acc.totalEmployees[month] = data[month][totalEmployees];

      const { [totalEmployees]: _, ...rest } = data[month];
      acc.reportData[month] = { ...rest };
      return acc;
    },
    { reportData: {}, totalEmployees: {} } as {
      reportData: IAttritionReportResponse;
      totalEmployees: { [key: string]: number };
    }
  );
  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Attrition Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
        reportType={attritionReportType}
        setAllFilters={setAllFilters}
      />
      <FormControl>
        <RadioGroup
          row
          name='attrition_report_type'
          onChange={handleChangeAttritionReportType}
          value={attritionReportType}
        >
          {attritionReportValues.map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!!Object.keys(reportData).length && (
        <Stack direction={'row'} spacing={2}>
          <Box width='45%' minHeight={300} pt={3}>
            <ChartAppAttrition
              data={reportData}
              totalEmployees={totalEmployees}
            />
          </Box>
          <Box width='55%' pt={3}>
            <AttritionTable
              rows={reportData}
              attritionType={attritionReportType}
              totalEmployees={totalEmployees}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
