import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { IAttritionReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import TooltipRenderer from './ChartTooltip';

type DailyStars = {
  date: string;
  count: number;
};
type Series = {
  label: string;
  data: DailyStars[];
};

export function ChartAppAttrition({
  data,
  totalEmployees,
}: {
  data: IAttritionReportResponse;
  totalEmployees: { [key: string]: number };
}) {
  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.date,
      position: 'left',
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.count,
        formatters: {
          scale: (value: any) => {
            return `${Math.round(value)}`;
          },
        },
        stacked: true,
        position: 'bottom',
      },
    ],
    []
  );

  const dataResult: Series[] = useMemo(() => {
    return Object.entries(data).reduce(
      (
        acc: { label: string; data: any[] }[],
        [date, obj]: [string, { [key: string]: number }]
      ) => {
        Object.entries(obj).forEach(([nameKey, value]) => {
          const founded = acc.find(({ label }) => label === nameKey);
          if (founded) {
            founded.data.push({ date, count: value });
          } else {
            acc.push({ label: nameKey, data: [{ date, count: value }] });
          }
        });
        return acc;
      },
      []
    );
  }, [data]);

  return dataResult?.length > 0 ? (
    <Chart
      options={{
        data: dataResult,
        primaryAxis,
        secondaryAxes,
        tooltip: {
          render: (tooltipItem) => (
            <TooltipRenderer {...tooltipItem} totalEmployees={totalEmployees} />
          ),
        },
      }}
    />
  ) : (
    <div>No data</div>
  );
}
