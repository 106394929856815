import {
  useGetInterviewReportMutation,
  useGetExportInterviewFileMutation,
} from '../../EmployeesPage/api/apiSlice';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { ChartAppInterview } from './ChartAppInterview';
import { IInterviewReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import Export from '../../common/Export';
import Filters from '../Filters/Filters';
import InterviewTable from './InterviewTable';

const interviewTypes = {
  all: 'All interviews',
  customer: 'Customers',
};

export const InterviewReport = () => {
  const [getReport] = useGetInterviewReportMutation();
  const [downloadFile] = useGetExportInterviewFileMutation();
  const [data, setData] = useState<IInterviewReportResponse>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [allFilters, setAllFilters] = useState({});
  const [interviewReportType, setInterviewReportType] = useState('customer');
  const handleChangeAttritionReportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInterviewReportType(event.target.value as string);
  };

  const interviewTypeValues = useMemo(() => {
    if (interviewTypes) {
      return Object.entries(interviewTypes);
    }
    return [];
  }, [interviewTypes]);

  const { allData, failedData, totalActiveEmployees } = Object.keys(
    data
  ).reduce(
    (acc, month) => {
      const totalActiveEmployeesKey = 'Total active employees';
      const failedKey = 'Employees with 2+ failed interviews';
      acc.totalActiveEmployees[month] = data[month][totalActiveEmployeesKey];
      acc.failedData[month] = { [failedKey]: data[month][failedKey] };
      const {
        [totalActiveEmployeesKey]: _,
        [failedKey]: __,
        ...rest
      } = data[month];
      acc.allData[month] = { ...rest };
      return acc;
    },
    { allData: {}, failedData: {}, totalActiveEmployees: {} } as {
      allData: IInterviewReportResponse;
      failedData: IInterviewReportResponse;
      totalActiveEmployees: { [key: string]: number };
    }
  );

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Interview Result Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
        reportType={interviewReportType}
        setAllFilters={setAllFilters}
      />
      <FormControl>
        <RadioGroup
          row
          name='interview_report_type'
          onChange={handleChangeAttritionReportType}
          value={interviewReportType}
        >
          {interviewTypeValues.map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!!data && (
        <Stack direction={'row'} spacing={2}>
          <Box width={400} pt={3}>
            <Box>
              <h3>Interview result</h3>
            </Box>
            <Box height={'90%'} position={'relative'}>
              <ChartAppInterview data={allData} />
            </Box>
          </Box>
          <Box width={400} pt={3}>
            <Box>
              <h3>Employees with 2+ failed interviews</h3>
            </Box>
            <Box height={'90%'}>
              <ChartAppInterview
                data={failedData}
                totalActiveEmployees={totalActiveEmployees}
              />
            </Box>
          </Box>
          <Box width={600} pt={3}>
            <InterviewTable rows={data} allData={allData} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
