export const downloadExportFile = async (response: Response) => {
  const fileName = response.headers
    .get('content-disposition')
    ?.split('filename=')[1]
    .slice(1, -1);
  const fileBlob = await response.blob();
  const url = window.URL.createObjectURL(fileBlob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName!;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
