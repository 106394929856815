import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ILocationReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { getPercentages } from '../utils';

interface IData {
  date: string;
  total: number;
  topCountries: { count?: number; location?: string }[];
  other: number;
}
const MAX_COUNTRIES_COLUMN = 5;

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

const isMoreThanTenPercent = (count: number, totalEmployees: number): boolean =>
  // "Сountry" column contains only those countries with more than 10% of employees (for the current month)
  (count / totalEmployees) * 100 >= 10;

export default function LocationTable({
  rows,
}: {
  rows: ILocationReportResponse[];
}) {
  const data: IData[] = useMemo(() => {
    const cloneRows: ILocationReportResponse[] = cloneDeep(rows);

    return cloneRows.map((row) => {
      row.locations.sort((a, b) => b.count - a.count);
      const totalEmployees = row.locations.reduce(
        (acc, current) => (acc += current.count),
        0
      );

      const result = row.locations.reduce(
        (acc, current) => {
          if (acc.topCountries.length !== MAX_COUNTRIES_COLUMN) {
            if (isMoreThanTenPercent(current.count, totalEmployees)) {
              acc.topCountries.push(current);
              acc.other -= current.count;
            }
          }
          return acc;
        },
        {
          date: row.date,
          total: totalEmployees,
          topCountries: [],
          other: totalEmployees,
        } as IData
      );

      return result;
    });
  }, [rows]);

  // get the number of columns for Country
  const columnsCount = data.reduce((acc, current) => {
    if (acc > current.topCountries.length) {
      return acc;
    } else return current.topCountries.length;
  }, 0);

  // fill topCountries with empty objects so that they have the same length
  data.forEach((item) => {
    if (item.topCountries.length < columnsCount) {
      for (let i = item.topCountries.length; i < columnsCount; i++) {
        item.topCountries.push({});
      }
    }
  });

  return data.some((item) => item.topCountries.length) ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Month</TableCell>
            <WithBorder colSpan={columnsCount} align='center'>
              Country
            </WithBorder>
            <WithBorder align='center'>Others</WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.date}>
              <TableCell align='center'>{row.date}</TableCell>
              {row.topCountries.map((country, index) => (
                <WithBorder align='center' key={`${country.location}${index}`}>
                  <div>{country.location}</div>
                  {country.count
                    ? `${country.count}  (${getPercentages(
                        country.count,
                        row.total
                      )})`
                    : null}
                </WithBorder>
              ))}
              <WithBorder align='center'>
                {row.other
                  ? `${row.other}  (${getPercentages(row.other, row.total)})`
                  : null}
              </WithBorder>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
