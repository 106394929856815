import { Option, SkillRow } from '../../Bench/types';
import { ScopeType, ZoneType } from '../../../utils/permissions/types';

export interface IPaginationParams {
  search?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export interface UserInfo {
  id: number;
  name: string;
  avatar_url: string | null;
  username: string;
}

export type Item = { id: number; name: string };

export interface EmployeeFeedbackListItem {
  id: number;
  modified_by: Item;
  feedback_date: string;
  feedback_type: string;
  created: string;
  modified: string;
  risk_level: FeedbackRiskLevels;
  title: string;
  is_external: boolean;
}

export interface ITask {
  id?: number;
  short_summary: string;
  due_date: string;
  owner: {
    id: number;
    name: string;
    username?: string;
    avatar_url?: string | null;
    email?: string;
  };
  status?: 'created' | 'finished';
}
export interface OnSubmitTaskParams {
  owner_id: number;
  due_date: string;
  short_summary: string;
}
export interface OnUpdateTaskStatusParams {
  status: 'created' | 'finished';
}

export interface CvItem {
  comment: string;
  created_date: string;
  creator: object;
  file_url: string;
  id: number;
  is_archived: boolean;
  is_reviewed: boolean;
  project: string;
  reviewed_date: string | null;
  reviewer: string | null;
  role: string;
  title: string;
}

export interface CvsResponse {
  results: CvItem[];
}

export interface EmployeeCertificate {
  certificate_type: string;
  expiration_date: string;
}

export interface EmployeeContact {
  id?: number; // if id is present, it means that the contact is already saved in the database, new contacts don't have id

  // if name and contact are null, it means that the contact is going to be deleted
  name: string | null;
  contact: string | null;
}

export interface EmployeePhysicalLocation {
  id: number;
  country: string;
  city: string;
  theodolite_parsed: boolean;
}

export interface EmployeePosition {
  id: number;
  name: string;
}

export interface EmployeeSeniority {
  id: number;
  name: string;
}

export interface Office {
  id: number;
  name: string;
}

export interface ProjectDetails {
  id: number;
  name: string;
  delivery_managers: UserInfo[];
  engineering_managers: UserInfo[];
  primary_offices: Office[];
}

export interface EmployeeProject {
  name: string;
  role: string;
  status: string;
  assignment: string;
  assignment_type: string;
  team: { name: string; lead: Item | null } | null;
  //TODO: replace with a meaningful name (also in BE side)
  project: ProjectDetails;
}

export interface Project extends EmployeeProject {
  start_date: string;
  end_date: string;
}

export type EmployeeManager = {
  id: number;
  name: string;
  username: string;
  avatar_url: null | string;
};

export interface IEmployee {
  id: number;
  title: string;
  username: string;
  avatar_url: string;
  cell: string;
  certificates: EmployeeCertificate[];
  date_of_birth: string | null;
  email: string;
  employee_contacts: EmployeeContact[];
  employee_physical_location: EmployeePhysicalLocation;
  employee_position: EmployeePosition;
  employee_seniority: EmployeeSeniority;
  employee_status: string;
  english_level: string;
  location: string;
  manager: EmployeeManager;
  hr: null | EmployeeManager;
  hr_id: number | null; // mutation request only
  mentors: {
    id: number;
    name: string;
    username: string;
    avatar_url: null | string;
  }[];
  name: string;
  office: Office;
  projects: EmployeeProject[];
  seniority_confirmation: boolean;
  skills: { name: string; manager: UserInfo | null; self: string }[];
  start_date_in_company: string;
  team_lead: UserInfo | null;
  year_start_in_profession: number;
  backfill: {
    id: number;
    name: string;
    username: string;
    avatar_url: null | string;
  }[];
  zone?: ZoneType;
  scope?: ScopeType;
  is_team_lead?: boolean;
  is_mentor?: boolean;
  is_key_resource?: boolean;
  is_hr?: boolean;
  is_nonprod?: boolean;
  is_supplementary?: boolean;
  vacation: null | Vacation;
  questionnaire_updated_at: string | null;
  probation_status?: string;
  probation_due_date?: string | null;
  new_manager_for_employee: null | UserInfo;
  status_change_manager: null | string;
  linkedin_url?: null | string;
  no_linkedin?: boolean;
  bench_tags?: { id: number; name: string }[];
  main_technologies?: { name: string }[];
}

export interface Vacation {
  end_date: string;
  start_date: string;
  status: string;
  type: string;
}

export interface Skill {
  id: number;
  name: string;
  approved?: boolean;
  is_main_tech?: boolean;
  category: Option;
  categories: Option[] | [];
}

export interface SkillCategory {
  id: number;
  name: string;
  skills: Skill[];
}

export interface SkillLevel {
  id: number;
  name: string;
  order?: number;
}

export interface ListResponse<T> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export interface ListResponseWithScopeAndZone<T> extends ListResponse<T> {
  scope: ScopeType | '';
  zone: ZoneType | '';
}

export interface UserSkill {
  id: number;
  is_confirmed?: boolean;
  self_assessed_level: SkillLevel;
  skill: Skill;
  skill_category: Item;
  manager_assessed_level?: SkillLevel;
  updated_at?: string;
  current_main_tech: boolean;
  interview_enabled: boolean;
}

export interface FeedbackFormDataInterface {
  id?: number;
  title?: string;
  // todo: fix types on select fields
  risk_level?: any;
  feedback_type?: any;
  feedback_type_id?: number;
  feedback_type_name?: string;
  reporter_comments?: string;
  feedback_date?: string;
  employee_plans_goals?: string;
  employee_comments?: string;
  resolution_date?: string;
  resolution_comment?: string;
  feedback_tasks?: OnSubmitTaskParams[];
  non_actual?: boolean;
}

export interface PostFeedbackInterface extends FeedbackFormDataInterface {
  checklist: ChecklistInterface | null;
  is_external: boolean;
  offset: string;
}

export interface Feedback {
  id: number;
  checklist?: ChecklistItemInterface;
  title: string;
  content: string;
  created: string;
  modified: string;
  feedback_type: string;
  risk_level: string;
  feedback_date: string;
  is_external: boolean;
}

export interface ChecklistItemInterface {
  checkbox_answer: boolean | undefined;
  question: string;
  order: number;
  question_type: 'scale' | 'text' | 'checkbox';
  scale_answer: number | undefined;
  text_answer: string | undefined;
  last_change_by?: string;
}

export interface ChecklistInterface {
  id: number;
  items: Array<ChecklistItemInterface>;
  name: string;
}

export interface FeedbackResponseInterface {
  checklist: ChecklistInterface;
  reporter_comments: string;
  employee_comments?: string;
  employee_plans_goals?: string;
  created: string;
  employee: {
    title: string;
    id: number;
    name: string;
  };
  feedback_date: string;
  feedback_type: number;
  id: number;
  is_external: boolean;
  manager: { name: string; id: number };
  modified: string;
  modified_by: Item;
  position_id: number;
  risk_level: string;
  title: string;
  resolution_date: string;
  resolution_comment?: string;
  feedback_tasks: {
    id: number;
    short_summary: string;
    due_date: string;
    owner: {
      id: number;
      name: string;
      username: string;
      email: string;
      avatar_url: string;
    };
    status: 'created' | 'finished';
  }[];
  non_actual: boolean;
}

export interface EmployeeMinimalResponseInterface {
  avatar_url: null | string;
  id: number;
  name: string;
}

export interface MentorsMinimalResponseInterface {
  avatar_url: null | string;
  id: number;
  name: string;
}

export enum FeedbackRiskLevels {
  DANGER = 'danger',
  WARNING = 'warning',
  RESOLVED = 'resolved',
  NO_RISK = 'unknown',
}

export type DictionaryElement = { [p: string]: string };

export interface IEmployeeFiltersResponse {
  employee_status: DictionaryElement[];
  managers: UserInfo[];
  offices: Item[];
  positions: Item[];
  probation_status: DictionaryElement[];
  projects: Item[];
  skills: { id: number; name: string; approved: boolean }[];
  interview_enabled_skills: {
    id: number;
    name: string;
    approved: boolean;
  }[];
}

export type Item2 = { id: string; name: string };

export interface IEmployeeFiltersPrepared {
  employee_status: Item2[];
  managers: UserInfo[];
  offices: Item[];
  positions: Item[];
  probation_status: Item2[];
  projects: Item[];
  skills: { id: number; name: string; approved: boolean }[];
  interview_enabled_skills: {
    id: number;
    name: string;
    approved: boolean;
  }[];
}

export enum ProbationStatuses {
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export interface IEmployeeOrgInfoResponse {
  avatar_url: string | null;
  bench_tags: IBenchTag[];
  id: number;
  name: string;
  probation_due_date: string | null;
  probation_prolongation_reason: string | null;
  probation_status: string;
  username: string;
}

export interface IEmployeeOrgInfoEditRequest {
  probation_due_date?: string | null;
  probation_prolongation_reason?: string | null;
  probation_status?: string;
  bench_tags?: IBenchTag[];
}

export interface IProbationStatus {
  status: any;
}

export interface IBenchTag {
  id: number;
  name: string;
}

export interface IFeedback {
  cdp?: null;
  checklist: null | ChecklistInterface;
  content: string;
  created: string;
  created_by: UserInfo;
  employee: any;
  event?: null;
  feedback_date: string;
  id: number;
  is_external: boolean;
  modified: string;
  modified_by: {
    id: number;
    name: string;
  };
  risk_level: string;
  title: string;
  feedback_type: number;
  risk?: {
    id: string;
    name: string;
  };
}

export interface IFeedbacksResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Array<IFeedback>;
}

export interface IFeedbacksRequest {
  search?: string;
  limit?: number;
  offset?: number;
  feedback_date_after?: string | null;
  feedback_date_before?: string | null;
  ordering?: string;
}

export interface IFeedbackCreateBody {
  title: string;
  reporter_comments: string;
  risk_level: string;
  feedback_type_id: number;
  feedback_type_name: string;
  feedback_date: string;
  checklist: ChecklistInterface | null;
  is_external: boolean;
  offset: string;
  employee_comments?: string;
  employee_plans_goals?: string;
  feedback_tasks?: OnSubmitTaskParams[];
}

export interface IRiskReportResponse {
  date: string;
  risks: {
    count: number;
    type: string;
  }[];
  total: number;
}

export interface ILocationReportResponse {
  date: string;
  locations: {
    count: number;
    location: string;
  }[];
}
export interface IInterviewReportResponse {
  [key: string]: {
    [key: string]: number;
  };
}

export interface IAttritionReportResponse {
  [key: string]: {
    [key: string]: number;
  };
}
type AttritionChartType = 'dismissed_reason' | 'age' | 'working_period';
type InterviewChartType = 'customer' | 'all';
export interface IFilterReportParams {
  office?: string;
  months?: number;
  manager?: string;
  project?: number;
  status?: string;
  seniorities?: string;
  bench_tags?: IBenchTag[];
  not_bench_tags?: IBenchTag[];
  chartType?: AttritionChartType | InterviewChartType;
}

export interface IManagerChangeResponse {
  employee_id: number;
  employee_name: string;
  manager_id: number;
  manager_name: string;
  new_manager_id: number;
  new_manager_name: string;
  status: string;
  avatar_url: string;
}

export interface IManagerChange {
  employeeId: number;
  employeeName: string;
  managerId: number;
  managerName: string;
  newManagerId: number;
  newManagerName: string;
  status: string;
  avatarUrl: string;
}

export interface IUserEditData {
  new_manager_for_employee_id?: null | number;
  status_change_manager?: null | string; // only "true" as string
  bench_comment?: string;
}

export interface IBenchResponse {
  active_projects: [];
  avatar_url: string;
  bench_start_date: string;
  employee_physical_location: string;
  employee_position: Item;
  employee_seniority: Item;
  historical_projects: [{ name: string }];
  id: number;
  main_technologies: [];
  name: string;
  skill_stack: [];
  start_date_in_company: string;
  username: string;
  year_start_in_profession: number;
  bench_tags: IBenchTag[];
  bench_comment: string;
}

export interface IBenchRequest {
  limit?: number;
  offset?: number;
  ordering?: string;
  offices?: Item[];
  positions?: Item[];
  seniorities?: Item[];
  mainTechValues?: Skill[];
  tags?: Item[];
  tagsToExclude?: Item[];
  skillRows?: SkillRow[];
  isIncludeUnconfirmed?: boolean;
}

export interface IBenchData {
  id: number;
  name: string;
  location: string;
  startDateOnBench: string;
  seniority: string;
  position: string;
  yearStartInProfession: number;
  startDateInCompany: string;
  mainTech: [];
  stack: [];
  workedPreviouslyOn: [{ name: string }];
  currentUtilization: [];
  tags: IBenchTag[];
  avatarUrl: string;
  comment: string;
}

export interface OfficeBenchSummary {
  office_name: string;
  total_count: number;
  billable_count: number;
  billable_pct: number;
  internal_count: number;
  internal_pct: number;
  invested_count: number;
  invested_pct: number;
  long_internal_count: number;
  long_internal_pct: number;
}

export interface IBenchSummary {
  offices: OfficeBenchSummary[];
  total: Omit<OfficeBenchSummary, 'office_name'>;
}

export interface IBenchFilters {
  offices: Item[];
  seniorities: Item[];
  positions: Item[];
  bench_tags: IBenchTag[];
  main_tech_skills: Skill[];
  tags: IBenchTag[];
}

export interface ICDPRequest {
  employee_id: number;
  short_summary: string;
  mentor_id: number;
  position?: string;
  seniority_level?: string;
  notes?: string;
  files_ids?: number[];
  cdp_goals?: IGoalPostPayload[];
}

// todo: remove any from 'progress'
export interface ICDPResponse {
  id: number;
  status: string;
  progress: any;
  notes: string;
  short_summary: string;
  position: string;
  seniority_level: string;
  start_date: string;
  due_date: string;
  employee: {
    id: number;
    name: string;
    username: string;
    email: string;
    avatar_url: string;
  };
  mentor: {
    id: number;
    name: string;
    username: string;
    email: string;
    avatar_url: string;
  };
  files: ICDPFileResponse[];
  closed_goals: number;
  total_goals: number;
  cdp_goals: IGoal[];
}

export interface ICDPFileResponse {
  id: number;
  file_name: string;
  file_url: string;
  file_size: number;
  uploaded_at: string;
}

export interface IEmployeeCDPListResponse {
  id: number;
  status: string;
  short_summary: string;
  modified_date: string;
  employee: UserInfo;
  mentor: UserInfo;
  closed_goals: number;
  total_goals: number;
  due_date: string;
}

// Goal
export interface IGoal {
  id: number;
  short_summary: string;
  due_date: string;
  mentor: UserInfo;
  comment: string;
  actions: string;
  is_closed: boolean;
}

export interface IGoalInState {
  id: number;
  short_summary: string;
  due_date: string;
  mentor: Item;
  actions: string;
  comment?: string;
  is_closed?: boolean;
}

export interface OnSubmitCDPGoalParams {
  short_summary: string;
  mentor: Item;
  due_date: string;
  actions: string;
}

export interface IGoalPostPayload {
  mentor_id: number;
  short_summary: string;
  due_date: string;
  actions: string;
  comment?: string;
}

export interface IGoalResponse {
  id: number;
  mentor: UserInfo;
  actions: string;
  due_date: string;
  short_summary: string;
  comment: string;
  is_closed: boolean;
}

export interface IGoalPatchPayload {
  short_summary?: string;
  mentor_id?: number;
  due_date?: string;
  actions?: string;
  comment?: string;
  is_closed?: boolean;
}

export interface IEmployeeDetails extends IEmployee {
  is_team_lead: boolean;
  is_mentor: boolean;
  is_key_resource: boolean;
  is_hr: boolean;
  is_nonprod: boolean;
  is_supplementary: boolean;
}

export interface IEnglishLevel {
  title: Item2;
}

export interface IEmployeePatchRequest {
  employee_physical_location?: {
    country?: string;
    city: string | null;
  };
  employee_position_id?: number;
  employee_seniority_id?: number;
  date_of_birth?: string;
  year_start_in_profession?: number;
  english_level?: string;
  employee_contacts?: Array<{
    id?: number;
    name: string | null;
    contact: string | null;
  }>;
  is_hr?: boolean;
  is_key_resource?: boolean;
  is_mentor?: boolean;
  is_nonprod?: boolean;
  is_supplementary?: boolean;
  is_team_lead?: boolean;
  backfill_ids?: number[];
  mentors_ids?: number[];
  status_change_manager?: 'true' | null;
  new_manager_for_employee_id?: number | null;
  hr_id?: number;
  dismissed_date?: string;
  dismissed_reason?: string;
  linkedin_url?: string | null;
  no_linkedin?: boolean;
}

export interface ICertificateResponse {
  id: number;
  certificate_name: string;
  file_url: string;
  creator: {
    id: number;
    name: string;
    username: string;
    avatar_url: string | null;
  };
  file_name: string;
}
export interface ICertificateRequest {
  certificate_name: string;
  employee_id: number;
  file?: File;
}

export type ICountryResponse = {
  country: string;
};

export interface VersionResponse {
  id: number;
  deploy_date: string;
  pipeline_id: string;
  version: string;
}
export type AttritionReportType = 'age' | 'dismissed_reason' | 'working_period';

export type AttritionReportTypesResponse = AttritionReportTypes[];

export type AttritionReportTypes = {
  [key in AttritionReportType]: string;
};

export interface IEmployeeLocationHistory {
  location: string;
  history_date: string;
}

export interface ICDPReportResponse {
  [key: string]: {
    'Active CDPs': number;
    'Out of date CDPs': number;
    'Finished in time CDPs': number;
    'Finished out of time CDPs': number;
  };
}
export interface ISeniorityReportResponse {
  // TODO: may change
  [key: string]: {
    'Total employees': number;
    Team: { [key: string]: number };
    Ideal: { [key: string]: number };
  };
}
