import {
  useGetExportSeniorityFileMutation,
  useGetSeniorityReportMutation,
} from '../../EmployeesPage/api/apiSlice';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { ISeniorityReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import Filters from '../Filters/Filters';
import Export from '../../common/Export';
import { ChartAppSeniority } from './ChartAppSeniority';
import BasicTable from './BasicTable';

export const SeniorityReport = () => {
  const [getReport] = useGetSeniorityReportMutation();
  const [data, setData] = useState<ISeniorityReportResponse>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [downloadFile] = useGetExportSeniorityFileMutation();
  const [allFilters, setAllFilters] = useState({});

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Seniority Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
        setAllFilters={setAllFilters}
        hasSeniority={false}
        isSeniorityReport={true}
      />
      {!!Object.keys(data).length && (
        <Stack direction={'row'} spacing={2}>
          <Box width={640} height={320} pt={3}>
            <ChartAppSeniority data={data} />
          </Box>
          <Box pt={3}>
            <Box>
              <h3>Employees</h3>
            </Box>
            <BasicTable
              rows={Object.entries(data).map(([key, value]) => ({
                date: key,
                ...value,
              }))}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
