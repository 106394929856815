export const defaultColors = [
  '#ff6961',
  '#fdfd96',
  '#77dd77',
  '#666666',
  '#4682B4',
  '#00FFFF',
  '#0099FF',
  '#3E3BF5',
  '#444444',
  '#3366CC',
  '#3399FF',
  '#fddf11',
  '#3366CC',
  '#0099CC',
  '#336699',
  '#3333FF',
  '#2178BA',
  '#ff1122',
  '#1F7AB8',
  '#1C7DB5',
];

export const getPercentages = (count: number, total: number): string => {
  if (!count || !total) {
    return `0%`;
  } else return `${((count / total) * 100).toFixed(1)}%`;
};
