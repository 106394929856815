import {
  useGetExportRiskFileMutation,
  useGetRiskReportMutation,
} from '../../EmployeesPage/api/apiSlice';
import { Box, Grid, Stack } from '@mui/material';
import { IRiskReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import BasicTable from './BasicTable';
import { filterDataWithTypes, transformDataStructureForTable } from './utils';
import Export from '../../common/Export';
import Filters from '../Filters/Filters';
import { useState } from 'react';
import { ChartAppRisk } from './ChartAppRisk';

export const RiskReport = () => {
  const [getReport] = useGetRiskReportMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IRiskReportResponse[]>([]);
  const [allFilters, setAllFilters] = useState({});
  const [downloadFile] = useGetExportRiskFileMutation();

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Risk Trend Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setAllFilters={setAllFilters}
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
      />
      {!!data?.length && (
        <Stack direction={'row'} spacing={2}>
          <Box width={400} pt={3}>
            <Box>
              <h3>Employees with risk still opened by the month</h3>
            </Box>
            <Box height={'90%'} position={'relative'}>
              <ChartAppRisk
                data={filterDataWithTypes(data, ['danger', 'warning'])}
                colors={['#ff6961', '#fdfd96']}
              />
            </Box>
          </Box>
          <Box width={450} pt={3}>
            <Box>
              <h3>Employees with Risk Closed in the previous month</h3>
            </Box>
            <Box height={'90%'}>
              <ChartAppRisk
                data={filterDataWithTypes(data, ['resolved', 'dismissed'])}
                colors={['#77dd77', '#444444']}
              />
            </Box>
          </Box>
          <Box width={600} pt={3}>
            <Box>
              <h3>Employees</h3>
            </Box>
            <BasicTable rows={transformDataStructureForTable(data)} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
