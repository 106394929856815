import { IFilterReportParams } from '../apiSlice.types';
import { convertToFilterStr } from './convertToFilterStr';

export const getReportFilterParams = ({
  office,
  months,
  manager,
  project,
  status,
  seniorities,
  bench_tags,
  not_bench_tags,
}: IFilterReportParams) => {
  let paramsString = convertToFilterStr({
    tags: bench_tags,
    tagsToExclude: not_bench_tags,
  });
  if (paramsString.length) {
    paramsString = paramsString.replace(/&$/, '');
  }
  return {
    paramsString,
    params: {
      office,
      months,
      ...(manager ? { manager } : {}),
      ...(project ? { project } : {}),
      ...(status ? { status } : {}),
      ...(seniorities ? { seniorities } : {}),
    },
  };
};
