import { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextField from './TextField';
import { useGetEmployeeFiltersQuery } from '../../EmployeesPage/api/apiSlice';
import {
  IBenchTag,
  IFilterReportParams,
} from '../../EmployeesPage/api/apiSlice.types';
import { useSelector } from 'react-redux';
import { selectOffice } from '../../../store/slices/auth/authSlice';
import TagsSelect from './TagsSelect';
import { PERIODS, SENIORITIES, STATUS, TAGS } from './consts';
interface IProps {
  setAllFilters?: React.Dispatch<React.SetStateAction<IFilterReportParams>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getReport: (val: IFilterReportParams) => any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  reportType?: string;
  hasSeniority?: boolean;
  isSeniorityReport?: boolean;
}

export default function Filters({
  setAllFilters,
  setLoading,
  getReport,
  setData,
  reportType,
  hasSeniority = true,
  isSeniorityReport,
}: IProps) {
  const office = useSelector(selectOffice);

  const [selectedTags, setSelectedTags] = useState<{
    bench_tags: IBenchTag[];
    not_bench_tags: IBenchTag[];
  }>(TAGS);

  const {
    data: { offices, managers, projects } = {
      offices: [],
      managers: [],
      projects: [],
    },
  } = useGetEmployeeFiltersQuery();

  const initialValues: any = {
    office: office.name,
    months: 6,
    manager: '',
    project: '',
    status: '',
    seniorities: '',
    ...selectedTags,
  };

  useEffect(() => {
    fetchReport(initialValues);
    setAllFilters && setAllFilters(initialValues);
  }, []);

  // required for reports that switch between chart types to save selected filters
  const [reportTypeFilters, setReportTypeFilters] = useState(initialValues);

  useEffect(() => {
    reportType &&
      fetchReport({
        ...reportTypeFilters,
        chartType: reportType,
      });
  }, [reportTypeFilters, reportType]);

  const fetchReport = async ({
    office,
    months,
    manager,
    project,
    status,
    seniorities,
    bench_tags,
    not_bench_tags,
    chartType,
  }: IFilterReportParams) => {
    try {
      setLoading(true);
      const response: any = await getReport({
        office,
        months,
        manager,
        project,
        status,
        seniorities,
        bench_tags,
        not_bench_tags,
        chartType,
      });
      setData(response?.data || []);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onSubmit = (values: IFilterReportParams) => {
    if (reportType) {
      setReportTypeFilters(values);
    } else {
      fetchReport(values);
    }
    setAllFilters && setAllFilters(values);
  };

  const onReset = async (formik: any) => {
    const values = { ...initialValues, ...TAGS };
    setSelectedTags(TAGS);
    await formik.resetForm(values);
    if (reportType) {
      setReportTypeFilters(values);
    } else {
      await fetchReport(values);
    }
    setAllFilters && setAllFilters(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item width={240}>
              <Field name='office'>
                {({ field }: any) => (
                  <TextField
                    disabled
                    field={field}
                    formik={formik}
                    initialValues={initialValues}
                    label={'Delivery Center'}
                    data={offices}
                    valueProperty='name'
                  />
                )}
              </Field>
            </Grid>
            <Grid item width={160}>
              <Field name='months'>
                {({ field }: any) => (
                  <TextField
                    {...{ field, formik, initialValues }}
                    label={'Period'}
                    data={PERIODS}
                  />
                )}
              </Field>
            </Grid>
            <Grid item width={160}>
              <Field name='project'>
                {({ field }: FieldProps) => (
                  <TextField
                    {...{ field, formik, initialValues }}
                    label={'Project'}
                    data={projects}
                  />
                )}
              </Field>
            </Grid>
            <Grid item width={160}>
              <Field name='manager'>
                {({ field }: any) => (
                  <TextField
                    {...{ field, formik, initialValues }}
                    label={'Manager'}
                    data={managers}
                  />
                )}
              </Field>
            </Grid>
            <Grid item width={160}>
              <Field name='status'>
                {({ field }: any) => (
                  <TextField
                    {...{ field, formik, initialValues }}
                    label={'Status'}
                    data={
                      isSeniorityReport
                        ? STATUS.filter((item) => item.id !== 'non-prod')
                        : STATUS
                    }
                  />
                )}
              </Field>
            </Grid>
            {hasSeniority && (
              <Grid item width={160}>
                <Field name='seniorities'>
                  {({ field }: any) => (
                    <TextField
                      {...{ field, formik, initialValues }}
                      label={'Seniority'}
                      data={SENIORITIES}
                    />
                  )}
                </Field>
              </Grid>
            )}
            <Grid item>
              <TagsSelect
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                formik={formik}
              />
            </Grid>
            <Grid item>
              <Button sx={{ height: '40px' }} variant='contained' type='submit'>
                Submit
              </Button>
              <Button
                onClick={() => onReset(formik)}
                sx={{ height: '40px', marginLeft: '12px' }}
                variant='outlined'
                type='reset'
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
