import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import { ICDPReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { getPercentages } from '../utils';

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

export default function CDPTable({ rows }: { rows: ICDPReportResponse }) {
  const totalEmployees = useMemo(() => {
    return Object.keys(rows).reduce((acc, key) => {
      acc[key] = Object.values(rows[key]).reduce((result, value) => {
        return (result += value);
      }, 0 as number);
      return acc;
    }, {} as { [key: string]: number });
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Month</TableCell>
            <WithBorder align='center' colSpan={2}>
              Active
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Delayed
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Finished
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Finished out of time
            </WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows)
            .reverse()
            .map((key) => (
              <TableRow key={key}>
                <TableCell component='th' scope='row' align='center'>
                  {key}
                </TableCell>
                <WithBorder align='center'>
                  {rows[key]['Active CDPs']}
                </WithBorder>
                <TableCell align='center'>
                  {getPercentages(
                    rows[key]['Active CDPs'],
                    totalEmployees[key]
                  )}
                </TableCell>
                <WithBorder align='center'>
                  {rows[key]['Out of date CDPs']}
                </WithBorder>
                <TableCell align='center'>
                  {getPercentages(
                    rows[key]['Out of date CDPs'],
                    totalEmployees[key]
                  )}
                </TableCell>
                <WithBorder align='center'>
                  {rows[key]['Finished in time CDPs']}
                </WithBorder>
                <TableCell align='center'>
                  {getPercentages(
                    rows[key]['Finished in time CDPs'],
                    totalEmployees[key]
                  )}
                </TableCell>
                <WithBorder align='center'>
                  {rows[key]['Finished out of time CDPs']}
                </WithBorder>
                <TableCell align='center'>
                  {getPercentages(
                    rows[key]['Finished out of time CDPs'],
                    totalEmployees[key]
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
