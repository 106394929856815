import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/system';
import { IInterviewReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { getPercentages } from '../utils';
import { useMemo } from 'react';

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

export default function InterviewTable({
  rows,
  allData,
}: {
  rows: IInterviewReportResponse;
  allData: IInterviewReportResponse;
}) {
  const totalEmployees = useMemo(() => {
    return Object.keys(allData).reduce((acc, key) => {
      acc[key] = Object.values(allData[key]).reduce((result, value) => {
        return (result += value);
      }, 0 as number);
      return acc;
    }, {} as { [key: string]: number });
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Month</TableCell>
            <WithBorder align='center' colSpan={2}>
              Failed
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Wrong assignment
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Passed
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Employees with 2+ failed
            </WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((key) => (
            <TableRow key={key}>
              <TableCell component='th' scope='row' align='center'>
                {key}
              </TableCell>
              <WithBorder align='center'>
                {rows[key]['Failed interviews']}
              </WithBorder>
              <TableCell align='center'>
                {getPercentages(
                  rows[key]['Failed interviews'],
                  totalEmployees[key]
                )}
              </TableCell>
              <WithBorder align='center'>
                {rows[key]['Wrongly assigned interviews']}
              </WithBorder>
              <TableCell align='center'>
                {getPercentages(
                  rows[key]['Wrongly assigned interviews'],
                  totalEmployees[key]
                )}
              </TableCell>
              <WithBorder align='center'>
                {rows[key]['Passed interviews']}
              </WithBorder>
              <TableCell align='center'>
                {getPercentages(
                  rows[key]['Passed interviews'],
                  totalEmployees[key]
                )}
              </TableCell>
              <WithBorder align='center'>
                {rows[key]['Employees with 2+ failed interviews']}
              </WithBorder>
              <TableCell align='center'>
                {getPercentages(
                  rows[key]['Employees with 2+ failed interviews'],
                  rows[key]['Total active employees']
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
