import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Menu,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IBenchTag } from '../../EmployeesPage/api/apiSlice.types';
import { useMemo, useState } from 'react';
import { useGetBenchTagsQuery } from '../../EmployeesPage/api/apiSlice';
import { Field } from 'formik';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function TagsSelect({
  selectedTags,
  formik,
  setSelectedTags,
}: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: tags, isLoading } = useGetBenchTagsQuery();

  const selectedTagsCount = useMemo(
    () => selectedTags.bench_tags.length + selectedTags.not_bench_tags.length,
    [selectedTags]
  );

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          <Box
            sx={{
              fontSize: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArrowDropDownIcon />
          </Box>
        }
        variant={'outlined'}
        color={'inherit'}
        sx={{
          width: '96px',
          textTransform: 'none',
          height: 40,
          color: 'rgba(0, 0, 0, 0.6)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        <Typography
          noWrap
          sx={{
            color:
              selectedTags.bench_tags.length ||
              selectedTags.not_bench_tags.length
                ? '#000'
                : 'inherit',
          }}
        >
          {selectedTagsCount ? `${selectedTagsCount} tags` : 'Tags'}
        </Typography>
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ p: 2 }} width={560}>
          <Stack direction='row' spacing={2}>
            <Field name='bench_tags'>
              {({ field }: any) => (
                <Autocomplete
                  size={'small'}
                  multiple
                  disableCloseOnSelect
                  loading={isLoading}
                  options={tags || []}
                  getOptionLabel={(option: IBenchTag) => option.name}
                  style={{ width: '50%' }}
                  limitTags={1}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Included Tags'
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      size='small'
                      fullWidth
                      {...field}
                    />
                  )}
                  value={selectedTags.bench_tags}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(field.name, newValue);
                    setSelectedTags({
                      ...selectedTags,
                      bench_tags: newValue,
                    });
                  }}
                />
              )}
            </Field>
            <Field name='not_bench_tags'>
              {({ field }: any) => (
                <Autocomplete
                  size={'small'}
                  multiple
                  disableCloseOnSelect
                  loading={isLoading}
                  options={tags || []}
                  getOptionLabel={(option) => option.name}
                  style={{ width: '50%' }}
                  limitTags={1}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Excluded Tags'
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      size='small'
                      fullWidth
                    />
                  )}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(field.name, newValue);
                    setSelectedTags({
                      ...selectedTags,
                      not_bench_tags: newValue,
                    });
                  }}
                  value={selectedTags.not_bench_tags}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              )}
            </Field>
          </Stack>
        </Box>
      </Menu>
    </>
  );
}
