import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import {
  AttritionReportType,
  IAttritionReportResponse,
} from '../../EmployeesPage/api/apiSlice.types';
import { getPercentages } from '../utils';

interface IData {
  date: string;
  total: number;
  topReasons: { count?: number; reason?: string }[];
  others: number;
}

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

const MAX_COLUMNS = 5;

const secondColumnName = (attritionType: string): string => {
  if (attritionType === 'age') {
    return 'Age';
  } else if (attritionType === 'working_period') {
    return 'Periods';
  } else return 'Reasons';
};

const isMoreThanOnePercent = (count: number, totalEmployees: number): boolean =>
  // In the second column contains only those items with more than 1%
  (count / totalEmployees) * 100 >= 1;

export default function AttritionTable({
  rows,
  attritionType,
  totalEmployees,
}: {
  rows: IAttritionReportResponse;
  attritionType: AttritionReportType;
  totalEmployees: { [key: string]: number };
}) {
  const data: IData[] = useMemo(() => {
    return Object.keys(rows)
      .reverse()
      .reduce((acc: any, month: any) => {
        let others = Object.values(rows[month]).reduce((result, value) => {
          return (result += value);
        }, 0 as number);
        const topReasons = Object.entries(rows[month]).reduce(
          (acc: any, [key, val]: any) => {
            if (
              acc.length !== MAX_COLUMNS &&
              isMoreThanOnePercent(val, totalEmployees[month])
            ) {
              acc.push({ reason: key, count: val });
              others -= val;
            }

            return acc.sort((a: any, b: any) => b.count - a.count);
          },
          []
        );

        acc.push({
          date: month,
          topReasons,
          others,
          total: totalEmployees[month],
        });
        return acc;
      }, []);
  }, [rows]);

  // get the number of elements for the second column
  const columnsCount = data.reduce((acc, current) => {
    if (acc > current.topReasons.length) {
      return acc;
    } else return current.topReasons.length;
  }, 0);
  const useTruncate = data.some((item) => item.topReasons.length >= 3);

  // fill topReasons with empty objects so that they have the same length
  data?.forEach((item) => {
    if (item.topReasons.length < columnsCount) {
      for (let i = item.topReasons.length; i < columnsCount; i++) {
        item.topReasons.push({});
      }
    }
  });

  return data.some((item) => item.topReasons.length) ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Month</TableCell>
            <WithBorder colSpan={columnsCount} align='center'>
              {secondColumnName(attritionType)}
            </WithBorder>
            <WithBorder align='center'>Others</WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.date}>
              <TableCell align='center'>{row.date}</TableCell>
              {row.topReasons.map((reason, index) => (
                <WithBorder
                  align='center'
                  key={`${reason.reason}${index}`}
                  {...(useTruncate && {
                    sx: { maxWidth: '110px' },
                  })}
                >
                  <div
                    {...(useTruncate && {
                      style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '90px',
                        margin: '0 auto',
                      },
                      title: reason.reason,
                    })}
                  >
                    {reason.reason}
                  </div>
                  {reason.count
                    ? `${reason.count}  (${getPercentages(
                        reason.count,
                        row.total
                      )})`
                    : null}
                </WithBorder>
              ))}
              <WithBorder align='center'>
                {row.others
                  ? `${row.others}  (${getPercentages(row.others, row.total)})`
                  : null}
              </WithBorder>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
