import * as Yup from 'yup';
import { createRef, Ref, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import {
  DatePickerField,
  getFormattedDate,
} from '../../../../common/formikFormControls/DatePickerField';
import {
  selectEmployeeScope,
  selectEmployeeZone,
} from '../../../slices/employeesSlice';
import { SelectField } from '../../../../common/formikFormControls/SelectField';
import { TextField } from '../../../../common/formikFormControls/TextField';
import { CustomForm } from '../../../../common/formikFormControls/CustomForm';
import './FeedbackCreate.css';
import RadioButtonGroup from '../../../../common/form-items/radio-group/radio-group';
import CustomIcon from '../../../../common/icon';
import { Checklist } from '../../../../common/checklist/Checklist';
import {
  useAddNewFeedbackMutation,
  useEditFeedbackMutation,
  useGetEmployeeQuery,
  useGetFeedbackFiltersQuery,
  useGetFeedbackQuery,
  useGetTemplatesQuery,
} from '../../../api/apiSlice';
import { SelectFieldValue } from '../../types/types';
import usePrompt from '../../../../common/Guards/usePrompt';
import {
  ChecklistInterface,
  ChecklistItemInterface,
  FeedbackFormDataInterface,
  FeedbackResponseInterface,
  ITask,
  OnSubmitTaskParams,
} from '../../../api/apiSlice.types';
import { FeedbackCreateEditFormHeader } from './FeedbackCreateEditFormHeader';
import Box from '@mui/material/Box';
import {
  canCreateOnlyExternalFeedback,
  getCanAddInternalAndExternalFeedbacks,
} from '../../../../../utils/permissions/feedbacks';
import { TasksList } from '../Tasks/TasksList';
import { INTERVIEW_CHECKLIST, INTERVIEW_FEEDBACK } from '../consts';
import { defaultsDeep } from 'lodash';

const EXTERNAL_FEEDBACK = 'external';
const RESOLVED_RISK = 'resolved';
const UNKNOWN_RISK = 'unknown';

const setIdToTemplate = (templates: Array<ChecklistInterface> | undefined) =>
  templates
    ? templates.map((template: ChecklistInterface, idx: number) => ({
        ...template,
        id: idx,
      }))
    : [];

const getOptions = (options: Array<{ name: string; id: number }>) =>
  options.map((item) => ({
    label: item.name,
    value: item.id,
  }));

const FeedbackCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, feedbackId } = useParams();
  const formRef: Ref<any> = createRef();
  const { state } = useLocation();
  const { data: employee } = useGetEmployeeQuery(Number(id));

  const zone = useSelector(selectEmployeeZone);
  const scope = useSelector(selectEmployeeScope);

  const [checklist, setChecklist] = useState(null as ChecklistInterface | null);
  const [visibility, setVisibility] = useState<'internal' | 'external'>(
    'internal'
  );
  const [isDirtyForm, setDirty] = useState(false as boolean);
  const [initialValues, setInitialValues] = useState({
    feedback_date: DateTime.now().toFormat('yyyy-MM-dd'),
    risk_level: UNKNOWN_RISK,
  });
  const [isEditForm, setIsEditForm] = useState(false as boolean);
  const [isDisabledField, setIsDisabledField] = useState(false);
  const [checklistValidation, setChecklistValidation] = useState('');
  const [isInterviewFeedbackType, setIsInterviewFeedbackType] = useState(false);

  const [addNewFeedback] = useAddNewFeedbackMutation();
  const [editFeedback] = useEditFeedbackMutation();
  const { data: feedbackFilters, isFetching: isFetchingFilters } =
    useGetFeedbackFiltersQuery();
  const { data: templates, isFetching: isFetchingTemplates } =
    useGetTemplatesQuery();

  const checklistTemplates = useMemo(() => {
    const allTemplates = setIdToTemplate(templates);
    if (!isInterviewFeedbackType && !isEditForm) {
      return allTemplates.filter(
        (template) => template.name !== INTERVIEW_CHECKLIST
      );
    }
    return allTemplates;
  }, [templates, setIdToTemplate, isInterviewFeedbackType, isEditForm]);

  const autocompleteTemplateId = checklistTemplates.find(
    (template) => template.name === INTERVIEW_CHECKLIST
  )?.id;

  usePrompt('Changes you made will not be saved.', isDirtyForm);

  const { data: feedback, isError } = useGetFeedbackQuery(
    {
      employeeId: Number(id),
      feedbackId: Number(feedbackId),
    },
    { skip: !feedbackId }
  );
  const [tasks, setTasks] = useState<ITask[]>(feedback?.feedback_tasks || []);

  useEffect(() => {
    const pathname = state ? state.pathname : `/employees/${id}/feedbacks`;
    dispatch(
      setComponent({
        component: {
          pathname,
          text: 'Feedbacks',
        },
        isLink: true,
      })
    );
    if (canCreateOnlyExternalFeedback(scope, zone)) {
      setVisibility(EXTERNAL_FEEDBACK);
    }
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  useEffect(() => {
    if (feedback && feedbackId) {
      setIsEditForm(true);
      prepareInitialData();
      setTasks(feedback?.feedback_tasks);
    }
  }, [feedbackId, feedback]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required('Required')
      .max(300, 'Maximum 300 characters'),
    reporter_comments: Yup.string().trim().required('Required'),
    employee_plans_goals: Yup.string().trim().nullable(),
    employee_comments: Yup.string().trim().nullable(),
    feedback_date: Yup.string().nullable().required('Required'),
    feedback_type: Yup.lazy((value) =>
      typeof value === 'object'
        ? Yup.object().nullable().required('Required')
        : Yup.string().nullable().required('Required')
    ),
    risk_level: Yup.mixed()
      .nullable()
      .when([], {
        is: () => visibility === EXTERNAL_FEEDBACK,
        then: Yup.object().nullable().notRequired(),
        otherwise: Yup.lazy((value) =>
          typeof value === 'object'
            ? Yup.object().nullable().required('Required')
            : Yup.string().nullable().required('Required')
        ),
      }),
  });

  const prepareInitialData = () => {
    if (feedback) {
      const visibility = feedback.is_external ? 'external' : 'internal';
      setVisibility(visibility);
      setChecklist(feedback.checklist);
      const values = { ...feedback, checklist: feedback.checklist?.name };
      setInitialValues(values);
    }
  };

  const onRadioButtonChange = (...args: Array<any>) => setVisibility(args[1]);

  const onSubmit = () => formRef?.current?.handleSubmit();

  const onFormSubmit = async (data: FeedbackFormDataInterface) => {
    setDirty(false);
    if (isEditForm) {
      const updatedFeedback = await updateFeedback(data);
      navigate(`/employees/${id}/feedbacks/${updatedFeedback?.id}`, { state });
    } else {
      if (
        checklist?.name === INTERVIEW_CHECKLIST &&
        checklist?.items.filter(
          (item) => item.question_type === 'text' && !item.text_answer
        ).length
      ) {
        setChecklistValidation('Required');
        return;
      }
      const newFeedback = await createFeedback(data);
      navigate(`/employees/${id}/feedbacks/${newFeedback?.id}`, { state });
    }
  };

  const createFeedback = async (
    data: FeedbackFormDataInterface
  ): Promise<FeedbackResponseInterface> => {
    try {
      const feedback_tasks = (tasks || []).map(
        ({ owner, ...rest }): OnSubmitTaskParams => ({
          owner_id: owner!.id,
          ...rest,
        })
      );

      return await addNewFeedback({
        userId: Number(id),
        initialFeedback: {
          title: data.title,
          risk_level:
            visibility === EXTERNAL_FEEDBACK
              ? null
              : data?.risk_level?.value || data.risk_level,
          feedback_type_id: Number(data?.feedback_type?.value),
          feedback_type_name: data?.feedback_type?.label,
          feedback_date: getFormattedDate(data.feedback_date),
          reporter_comments: data.reporter_comments,
          checklist,
          is_external: visibility === EXTERNAL_FEEDBACK,
          offset: getFormattedDate(data.feedback_date, 'ZZ'),
          employee_plans_goals: data.employee_plans_goals,
          employee_comments: data.employee_comments,
          feedback_tasks,
        },
      }).unwrap();
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const updateFeedback = async (
    data: FeedbackFormDataInterface
  ): Promise<FeedbackResponseInterface> => {
    try {
      return await editFeedback({
        id: Number(id),
        feedback: {
          id: data.id,
          reporter_comments: data.reporter_comments,
          employee_plans_goals: data.employee_plans_goals,
          employee_comments: data.employee_comments,
          feedback_type: data?.feedback_type?.value || data.feedback_type,
        },
      }).unwrap();
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const onCancel = () => {
    navigate(`/employees/${id}/feedbacks/${feedbackId || ''}`, { state });
  };

  const handleTemplateChange = (data: SelectFieldValue) => {
    if (!data) {
      return setChecklist(null);
    }
    const futureTemplate = checklistTemplates.find(
      (temp: ChecklistInterface) => temp.id === data.value
    );
    if (futureTemplate) {
      const template = defaultsDeep(checklist, futureTemplate);
      setChecklist({
        ...template,
        checklist_type: template.checklist_type ?? 'scale',
      });
    }
  };

  const handleChangeChecklist = (value: ChecklistItemInterface) => {
    if (checklist) {
      const items = checklist.items.map((item: ChecklistItemInterface) => {
        if (item.question === value.question) {
          return value;
        }
        return item;
      });
      setChecklist({ ...checklist, items });
    }
  };

  // todo: better to implement it on backend side
  const filterRisksByLevel = (risks = []) =>
    risks.filter((risk: { id: string }) => ![RESOLVED_RISK].includes(risk.id));

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' },
  ];

  const canAddInternalAndExternalFeedbacks =
    getCanAddInternalAndExternalFeedbacks(scope, zone);

  if (isError) {
    return (
      <Box mx={3}>
        <h2>Feedback not found</h2>
      </Box>
    );
  }

  /*If the feedback_type in the response is 'Interview Feedback' when editing the feedback, the field will be disabled.
  Otherwise, the 'Interview Feedback' type is removed from the list of feedback types*/
  const isInterviewFeedbackResponse = feedbackFilters?.type.some(
    (item: any) =>
      item.id === feedback?.feedback_type && item.name === INTERVIEW_FEEDBACK
  );
  const getFeedbackTypeOptions = () => {
    const options = getOptions(feedbackFilters?.type || []);
    if (isEditForm && !isInterviewFeedbackResponse) {
      return options.filter((type) => type.label !== INTERVIEW_FEEDBACK);
    } else return options;
  };

  return (
    <div className='page-cont'>
      <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
        <FeedbackCreateEditFormHeader
          isEditForm={isEditForm}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
        <Grid container alignItems='flex-start'>
          <Grid item xs={8}>
            <div className='feedback_create__form'>
              <span>Fill the following fields</span>
              <CustomForm
                formRef={formRef}
                formClassName='feedback_create__form__padding'
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                // @ts-ignore
                onSubmit={onFormSubmit}
                onTouchForm={() => setDirty(true)}
                {...{
                  handleTemplateChange,
                  setVisibility,
                  setIsDisabledField,
                  autocompleteTemplateId,
                  setIsInterviewFeedbackType,
                  isInterviewFeedbackType,
                }}
              >
                {/* @ts-ignore */}
                <TextField
                  name='title'
                  label='Short summary'
                  xs={6}
                  disabled={isEditForm}
                  isRequired
                />
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <label className='form_field__label'>
                    <span>Employee</span>
                  </label>
                  <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <Box
                      component={Link}
                      to={`/employees/${id}/feedbacks`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {employee?.name}
                    </Box>
                  </Box>
                </Box>
                {/* @ts-ignore */}
                <SelectField
                  name='feedback_type'
                  label='Type'
                  xs={6}
                  options={getFeedbackTypeOptions() || []}
                  isRequired
                  isLoading={isFetchingFilters}
                  disabled={isEditForm && isInterviewFeedbackResponse}
                />
                {/* @ts-ignore */}
                <SelectField
                  disabled={isEditForm || isDisabledField}
                  name='checklist'
                  label='Feedback template'
                  xs={6}
                  options={getOptions(checklistTemplates)}
                  onChange={handleTemplateChange}
                  isLoading={isFetchingTemplates}
                />
                {visibility !== EXTERNAL_FEEDBACK && (
                  /* @ts-ignore */
                  <SelectField
                    disabled={isEditForm}
                    name='risk_level'
                    label='Risk Level'
                    xs={6}
                    options={getOptions(
                      isEditForm
                        ? feedbackFilters?.risk || []
                        : filterRisksByLevel(feedbackFilters?.risk)
                    )} // Do not display 'Resolved' option during creating feedback
                    getValue={(option: { label: string; value: string }) =>
                      option?.value
                    }
                    isRequired
                    isLoading={isFetchingFilters}
                  />
                )}
                <DatePickerField
                  name='feedback_date'
                  label='Feedback Date'
                  maxDate={DateTime.now()}
                  isRequired
                  xs={6}
                  disabled={isEditForm}
                />
                {/* @ts-ignore */}
                <TextField
                  name='employee_comments'
                  label='Employee Feedback'
                  xs={12}
                  multiline
                  rows={4}
                />
                {/* @ts-ignore */}
                <TextField
                  name='reporter_comments'
                  label='General Feedback'
                  xs={12}
                  multiline
                  rows={4}
                  isRequired
                />
                {/* @ts-ignore */}
                <TextField
                  name='employee_plans_goals'
                  label='Employee Plans and Goals'
                  xs={12}
                  multiline
                  rows={4}
                />
              </CustomForm>
              <span>Visibility</span>
              <Grid container alignItems='center'>
                <RadioButtonGroup
                  isRow
                  handleChange={onRadioButtonChange}
                  selectedValue={visibility}
                  options={visibilityOptions}
                  disabled={
                    isEditForm ||
                    !canAddInternalAndExternalFeedbacks ||
                    isDisabledField
                  }
                />
                {visibility === EXTERNAL_FEEDBACK && (
                  <div className='feedback_create__visibility_help'>
                    {/* @ts-ignore */}
                    <CustomIcon iconName='info-icon' />
                    External Feedback will be visible outside of your Office
                  </div>
                )}
              </Grid>
              <Grid item>
                <TasksList
                  tasks={tasks}
                  setTasks={setTasks}
                  visibility={visibility}
                  feedbackRisk={feedback?.risk_level}
                />
              </Grid>
            </div>
          </Grid>
          {!!checklist && (
            <Grid item xs={4} className='feedback_create__checklist'>
              <Checklist
                checklist={checklist}
                onChange={handleChangeChecklist}
                disabled={isEditForm}
                {...(checklist.name === INTERVIEW_CHECKLIST && {
                  maxScale: 2,
                  checklistValidation,
                })}
              />
            </Grid>
          )}
        </Grid>
      </Scrollbars>
    </div>
  );
};

export default FeedbackCreate;
