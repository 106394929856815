import {
  DataGrid,
  GridActionsCellItem,
  GridComparatorFn,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { renderRiskCell } from './RenderRiskCell';
import './MuiDataGrid.override.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FeedbackRiskLevels } from '../../../api/apiSlice.types';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import { useEditFeedbackMutation } from '../../../api/apiSlice';

const EXTERNAL = 'external';

const feedbackRiskLevelComparator: GridComparatorFn<{
  risk: {
    id: string;
    name: string;
  };
  date: string;
}> = (v1, v2) => {
  const map = new Map();
  map.set(FeedbackRiskLevels.DANGER, 0);
  map.set(FeedbackRiskLevels.WARNING, 1);
  map.set(FeedbackRiskLevels.NO_RISK, 2);
  map.set(FeedbackRiskLevels.RESOLVED, 3);
  map.set(EXTERNAL, 4);

  if (map.get(v1.risk?.id) > map.get(v2.risk?.id)) {
    return 1;
  }
  if (map.get(v1.risk?.id) < map.get(v2.risk?.id)) {
    return -1;
  }

  if (v1?.date > v2?.date) {
    return -1;
  }
  if (v1?.date < v2?.date) {
    return 1;
  }

  return 0;
};

interface Row {
  type: string;
  risk: { id: string; name: FeedbackRiskLevels };
  editor: string;
  created: object;
}

interface Props {
  rows: Row[] | undefined;
  isLoading: boolean;
  isOfficeScope: boolean;
}

export function DataTable({ rows, isLoading, isOfficeScope }: Props) {
  // move up
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const [editFeedback] = useEditFeedbackMutation();

  const onCellClick = ({ id }: { id: any }) => {
    navigate(`${id}`, { state: { pathname: location.pathname } });
  };

  const doApprove = async (feedbackId: number) => {
    const response = await editFeedback({
      id: Number(id),
      feedback: { id: feedbackId, non_actual: true },
    }).unwrap();
    console.log('response', response);
  };

  const columns = [
    {
      headerName: 'Feedback Type',
      field: 'type',
      sortable: false,
      width: 260,
    },
    {
      headerName: 'Risk level',
      field: 'risk',
      renderCell: (params: GridRenderCellParams) =>
        params?.row?.is_external ? (
          <Chip label='External' variant='outlined' size='small' />
        ) : (
          renderRiskCell(params)
        ),
      width: 130,
      valueGetter: (params: GridValueGetterParams) => ({
        risk: params?.row?.is_external ? { id: EXTERNAL } : params.row.risk,
        date: params.row.created,
      }),
      sortComparator: feedbackRiskLevelComparator,
    },
    {
      headerName: 'Updated by',
      field: 'editor',
      sortable: false,
      width: 160,
    },
    {
      headerName: 'Feedback date',
      field: 'feedback_date',
      width: 160,
    },
    {
      headerName: 'Non actual',
      field: 'non_actual',
      width: 160,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 40,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<CancelOutlinedIcon />}
          label='Mark as non actual'
          disabled={!isOfficeScope}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            dispatch(
              // @ts-ignore
              showDialog(ConfirmationDialog, {
                dialogHeader: 'Mark as non actual',
                dialogTitle:
                  'Are you sure you want to mark this feedback as non actual?',
                closeDialog: (dialogName: string) =>
                  dispatch(closeDialog(dialogName)),
                confirmDialog: () => {
                  doApprove(params.id);
                  dispatch(closeDialog(ConfirmationDialog.NAME));
                },
              })
            );
          }}
        />,
      ],
    },
  ];

  return (
    <DataGrid
      rows={rows || []}
      columns={columns}
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      loading={isLoading}
      hideFooter
      onCellClick={onCellClick}
    />
  );
}
