import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { ISeniorityReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import TooltipRenderer from './ChartTooltip';

type DailyStars = {
  date: string;
  count: number;
  total: number;
};
type Series = {
  label: string;
  data: DailyStars[];
  secondaryAxisId?: any;
};

export function ChartAppSeniority({
  data,
}: {
  data: ISeniorityReportResponse;
}) {
  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.count,
        elementType: 'bar',
        formatters: {
          scale: (value: any) => {
            return `${Math.round(value)}`;
          },
        },
        min: 0,
      },
      {
        id: '2',
        getValue: (datum) => datum.count,
        elementType: 'line',
        formatters: {
          scale: (value: any) => {
            return `${Math.round(value)}`;
          },
        },
        position: 'left',
        min: 0,
      },
    ],
    []
  );

  const dataResult: Series[] = useMemo(() => {
    return Object.entries(data).reduce((acc, [date, obj]) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (key !== 'Total employees') {
          if (key === 'Ideal') {
            Object.entries(value).forEach(([nameKey, value]) => {
              const elem = acc.find(
                ({ label }: any) => label === 'Ideal ' + nameKey
              );
              if (elem) {
                elem.data.push({
                  date,
                  count: value,
                  total: obj['Total employees'],
                });
              } else {
                acc.push({
                  label: 'Ideal ' + nameKey,
                  data: [{ date, count: value, total: obj['Total employees'] }],
                });
              }
            });
          }
          if (key === 'Team') {
            Object.entries(value).forEach(([nameKey, value]) => {
              const elem = acc.find(({ label }: any) => label === nameKey);
              if (elem) {
                elem.data.push({
                  date,
                  count: value,
                  total: obj['Total employees'],
                });
              } else {
                acc.push({
                  label: nameKey,
                  data: [{ date, count: value, total: obj['Total employees'] }],
                });
              }
            });
          }
        }
      });
      return acc;
    }, [] as any);
  }, [data]);

  dataResult.forEach(
    (d: any, i) => (d.secondaryAxisId! = i > 2 ? '2' : undefined)
  );

  return dataResult?.length > 0 ? (
    <Chart
      options={{
        defaultColors: ['#0f83ab', '#faa43a', '#fd6868'],
        data: dataResult,
        primaryAxis,
        secondaryAxes,
        tooltip: {
          render: (tooltipItem) => <TooltipRenderer {...tooltipItem} />,
        },
      }}
    />
  ) : (
    <div>No data</div>
  );
}
