import {
  useGetCDPReportMutation,
  useGetExportCDPReportFileMutation,
} from '../../EmployeesPage/api/apiSlice';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { ChartAppCDP } from './ChartAppCDP';
import { ICDPReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import Filters from '../Filters/Filters';
import CDPTable from './Table';
import Export from '../../common/Export';

export const CDPReport = () => {
  const [getReport] = useGetCDPReportMutation();
  const [data, setData] = useState<ICDPReportResponse>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadFile] = useGetExportCDPReportFileMutation();
  const [allFilters, setAllFilters] = useState({});

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>CDP Report</h1>
        <Export onDownloadFile={() => downloadFile(allFilters)} />
      </Grid>
      <Filters
        setLoading={setLoading}
        setData={setData}
        getReport={getReport}
        setAllFilters={setAllFilters}
      />
      {!!Object.keys(data).length && (
        <Stack direction={'row'} spacing={2}>
          <Box width={'50%'} pt={3}>
            <ChartAppCDP data={data} />
          </Box>
          <Box width={'50%'} pt={3}>
            <CDPTable rows={data} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
