import { MenuItem, TextField as MuiTextField } from '@mui/material';
import ClearIcon from './ClearIcon';

export default function TextField({
  field,
  formik,
  initialValues,
  label,
  data,
  valueProperty = 'id',
  ...rest
}: any) {
  return (
    <MuiTextField
      select
      label={label}
      size='small'
      fullWidth
      InputProps={{
        endAdornment: (
          <ClearIcon
            field={field}
            initialValues={initialValues}
            onClick={() =>
              formik.setFieldValue(field.name, initialValues[field.name])
            }
          />
        ),
      }}
      {...field}
      {...rest}
    >
      {data.map((item: any) => (
        <MenuItem value={item[valueProperty]} key={`${item.name}-${item.id}`}>
          {item.name}
        </MenuItem>
      ))}
    </MuiTextField>
  );
}
