import * as Yup from 'yup';
import { createRef, Ref, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import {
  DatePickerField,
  getFormattedDate,
} from '../../../common/formikFormControls/DatePickerField';
import { SelectField } from '../../../common/formikFormControls/SelectField';
import { TextField } from '../../../common/formikFormControls/TextField';
import { CustomForm } from '../../../common/formikFormControls/CustomForm';
import './FeedbackCreate.css';
import RadioButtonGroup from '../../../common/form-items/radio-group/radio-group';
import CustomIcon from '../../../common/icon';
import { Checklist } from '../../../common/checklist/Checklist';
import {
  useCreateFeedbackMutation,
  useGetEmployeesMinimalQuery,
  useGetFeedbackFiltersQuery,
  useGetTemplatesQuery,
} from '../../../EmployeesPage/api/apiSlice';
import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';
import usePrompt from '../../../common/Guards/usePrompt';
import {
  ChecklistInterface,
  ChecklistItemInterface,
  ITask,
  OnSubmitTaskParams,
} from '../../../EmployeesPage/api/apiSlice.types';
import {
  selectFeedbacksScope,
  selectFeedbacksZone,
} from '../../slices/feedbacksSlice';
import {
  canCreateOnlyExternalFeedback,
  getCanAddInternalAndExternalFeedbacks,
} from '../../../../utils/permissions/feedbacks';
import { TasksList } from '../../../EmployeesPage/components/EmployeeFeedbacks/Tasks/TasksList';
import { INTERVIEW_CHECKLIST } from '../../../EmployeesPage/components/EmployeeFeedbacks/consts';
import defaultsDeep from 'lodash/defaultsDeep';

const EXTERNAL_FEEDBACK = 'external';
const RESOLVED_RISK = 'resolved';
const UNKNOWN_RISK = 'unknown';

interface SelectFieldValue {
  label: string;
  value: string | number;
}

interface IFormData {
  reporter_comments: string;
  employee: SelectFieldValue;
  feedback_date: DateTime;
  feedback_type: SelectFieldValue;
  title: string;
  risk_level: any;
  employee_comments?: string;
  employee_plans_goals?: string;
  tasks?: ITask[];
}

// remove setIdToTemplate
const setIdToTemplate = (templates: Array<ChecklistInterface> | undefined) =>
  templates
    ? templates.map((template: ChecklistInterface, idx: number) => ({
        ...template,
        id: idx,
      }))
    : [];

const getOptions = (options: Array<{ name: string; id: number }>) =>
  options.map((item) => ({
    label: item.name,
    value: item.id,
  }));

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Required')
    .max(300, 'Maximum 300 characters'),
  reporter_comments: Yup.string().trim().required('Required'),
  feedback_date: Yup.string().nullable().required('Required'),
  feedback_type: Yup.object().nullable().required('Required'),
  employee: Yup.object().nullable().required('Required'),
  risk_level: Yup.lazy((value) =>
    typeof value === 'object'
      ? Yup.object().nullable().required('Required')
      : Yup.string().nullable().required('Required')
  ),
  employee_comments: Yup.string().trim().nullable(),
  employee_plans_goals: Yup.string().trim().nullable(),
});

const initialValues = {
  feedback_date: DateTime.now(),
  risk_level: UNKNOWN_RISK,
};

export const FeedbackCreate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const formRef: Ref<any> = createRef();

  const zone = useSelector(selectFeedbacksZone);
  const scope = useSelector(selectFeedbacksScope);

  const [checklist, setChecklist] = useState<ChecklistInterface | null>(null);
  const [visibility, setVisibility] = useState('internal' as string);
  const [isDirtyForm, setDirty] = useState<boolean>(false);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [checklistValidation, setChecklistValidation] = useState('');
  const [isDisabledField, setIsDisabledField] = useState(false);
  const [isInterviewFeedbackType, setIsInterviewFeedbackType] = useState(false);

  const { data: feedbackFilters, isFetching: isFetchingFilters } =
    useGetFeedbackFiltersQuery();
  const { data: templates, isFetching: isFetchingTemplates } =
    useGetTemplatesQuery();
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeesMinimalQuery();
  const [createFeedback] = useCreateFeedbackMutation();

  const checklistTemplates = useMemo(() => {
    const allTemplates = setIdToTemplate(templates);
    if (!isInterviewFeedbackType) {
      return allTemplates.filter(
        (template) => template.name !== INTERVIEW_CHECKLIST
      );
    }
    return allTemplates;
  }, [templates, setIdToTemplate, isInterviewFeedbackType]);

  const autocompleteTemplateId = checklistTemplates.find(
    (template) => template.name === INTERVIEW_CHECKLIST
  )?.id;

  usePrompt('Changes you made will not be saved.', isDirtyForm);

  useEffect(() => {
    dispatch(
      setComponent({
        component: { pathname: '/feedbacks', text: 'Feedbacks' },
        isLink: true,
      })
    );
    if (canCreateOnlyExternalFeedback(scope, zone)) {
      setVisibility(EXTERNAL_FEEDBACK);
    }
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const onRadioButtonChange = (...args: Array<any>) => {
    if (tasks.length) {
      dispatch(
        // @ts-ignore
        showDialog(ConfirmationDialog, {
          dialogHeader: 'Attention',
          dialogTitle: 'All existing tasks will be deleted.',
          confirmButtonTitle: 'OK',
          closeButtonTitle: 'Cancel',
          confirmDialog: (dialogName: string) => {
            dispatch(closeDialog(dialogName));
            setTasks([]);
            setVisibility(args[1]);
          },
          closeDialog: (dialogName: string) =>
            dispatch(closeDialog(dialogName)),
        })
      );
    } else return setVisibility(args[1]);
  };

  const onSubmit = () => formRef?.current?.handleSubmit();

  const onFormSubmit = async (data: IFormData) => {
    setDirty(false);

    const feedback_tasks = (tasks || []).map(
      ({ owner, ...rest }): OnSubmitTaskParams => ({
        owner_id: owner!.id,
        ...rest,
      })
    );
    if (
      checklist?.name === INTERVIEW_CHECKLIST &&
      checklist?.items.filter(
        (item) => item.question_type === 'text' && !item.text_answer
      ).length
    ) {
      setChecklistValidation('Required');
      return;
    }
    const feedback = await createFeedback({
      userId: Number(data.employee.value),
      data: {
        title: data.title,
        reporter_comments: data.reporter_comments,
        risk_level:
          visibility === EXTERNAL_FEEDBACK
            ? null
            : data?.risk_level?.value || data.risk_level,
        feedback_type_id: Number(data?.feedback_type?.value),
        feedback_type_name: data?.feedback_type?.label,
        feedback_date: getFormattedDate(data.feedback_date),
        checklist,
        is_external: visibility === EXTERNAL_FEEDBACK,
        offset: getFormattedDate(data.feedback_date, 'ZZ'),
        employee_comments: data.employee_comments,
        employee_plans_goals: data.employee_plans_goals,
        feedback_tasks,
      },
    }).unwrap();
    navigate(`/employees/${data.employee.value}/feedbacks/${feedback.id}`, {
      state,
    });
  };

  const onCancel = () => {
    if (isDirtyForm) {
      dispatch(
        // @ts-ignore
        showDialog(ConfirmationDialog, {
          dialogHeader: 'Are you sure you want to cancel creating feedback?',
          dialogTitle: 'Changes you made will not be saved.',
          closeDialog: (dialogName: string) =>
            dispatch(closeDialog(dialogName)),
          confirmDialog: () => {
            setDirty(false);
            dispatch(closeDialog(ConfirmationDialog.NAME));
            navigate(-1);
          },
        })
      );
    } else {
      navigate(-1);
    }
  };

  const handleTemplateChange = (data: SelectFieldValue) => {
    if (!data) {
      return setChecklist(null);
    }
    const futureTemplate = checklistTemplates.find(
      (temp: ChecklistInterface) => temp.id === data.value
    );
    if (futureTemplate) {
      const template = defaultsDeep(checklist, futureTemplate);
      setChecklist({
        ...template,
        checklist_type: template.checklist_type ?? 'scale',
      });
    }
  };

  const handleChangeChecklist = (value: ChecklistItemInterface) => {
    if (checklist) {
      const items = checklist.items.map((item: ChecklistItemInterface) => {
        // item.id === value.id
        if (item.question === value.question) {
          return value;
        }
        return item;
      });
      setChecklist({ ...checklist, items });
    }
  };

  const renderHeader = () => (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      className='feedback_create__header'
    >
      <Grid item>
        <span className='feedback_create__title'>Create Feedback:</span>
      </Grid>
      <Grid item>
        <button className='button__secondary' onClick={onCancel}>
          Cancel
        </button>
        <button className='button__main' type='submit' onClick={onSubmit}>
          Submit
        </button>
      </Grid>
    </Grid>
  );

  const filterRisksByLevel = (risks = []) =>
    risks.filter((risk: { id: string }) => ![RESOLVED_RISK].includes(risk.id));

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' },
  ];

  const canAddInternalAndExternalFeedbacks =
    getCanAddInternalAndExternalFeedbacks(scope, zone);

  return (
    <div className='page-cont'>
      <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
        {renderHeader()}
        <Grid container alignItems='flex-start'>
          <Grid item xs={8}>
            <div className='feedback_create__form'>
              <span>Fill the following fields</span>
              <CustomForm
                formRef={formRef}
                formClassName='feedback_create__form__padding'
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                // @ts-ignore
                onSubmit={onFormSubmit}
                onTouchForm={() => setDirty(true)}
                {...{
                  handleTemplateChange,
                  setVisibility,
                  setIsDisabledField,
                  autocompleteTemplateId,
                  setIsInterviewFeedbackType,
                  isInterviewFeedbackType,
                }}
              >
                {/* @ts-ignore */}
                <TextField
                  name='title'
                  label='Short summary'
                  xs={6}
                  isRequired
                />
                {/* @ts-ignore */}
                <SelectField
                  isClearable={false}
                  name='employee'
                  label='Employee'
                  xs={6}
                  options={getOptions(employees || [])}
                  isRequired
                  isLoading={isFetchingEmployees}
                />
                {/* @ts-ignore */}
                <SelectField
                  isClearable={false}
                  name='feedback_type'
                  label='Type'
                  xs={6}
                  options={getOptions(feedbackFilters?.type || [])}
                  isRequired
                  isLoading={isFetchingFilters}
                />
                {/* @ts-ignore */}
                <SelectField
                  name='checklist'
                  label='Feedback template'
                  xs={6}
                  options={getOptions(checklistTemplates)}
                  onChange={handleTemplateChange}
                  isLoading={isFetchingTemplates}
                  disabled={isDisabledField}
                />
                {visibility !== EXTERNAL_FEEDBACK && (
                  /* @ts-ignore */
                  <SelectField
                    isClearable={false}
                    name='risk_level'
                    label='Risk Level'
                    xs={6}
                    options={getOptions(
                      filterRisksByLevel(feedbackFilters?.risk)
                    )}
                    getValue={(option: { label: string; value: string }) =>
                      option?.value
                    }
                    isRequired
                    isLoading={isFetchingFilters}
                  />
                )}
                <DatePickerField
                  name='feedback_date'
                  label='Feedback Date'
                  maxDate={DateTime.now()}
                  isRequired
                  xs={6}
                />
                {/* @ts-ignore */}
                <TextField
                  name='employee_comments'
                  label='Employee Feedback'
                  xs={12}
                  multiline
                  rows={4}
                />
                {/* @ts-ignore */}
                <TextField
                  name='reporter_comments'
                  label='General Feedback'
                  xs={12}
                  multiline
                  rows={4}
                  isRequired
                />
                {/* @ts-ignore */}
                <TextField
                  name='employee_plans_goals'
                  label='Employee Plans and Goals'
                  xs={12}
                  multiline
                  rows={4}
                />
              </CustomForm>
              <span>Visibility</span>
              <Grid container alignItems='center'>
                <RadioButtonGroup
                  isRow
                  handleChange={onRadioButtonChange}
                  selectedValue={visibility}
                  options={visibilityOptions}
                  disabled={
                    !canAddInternalAndExternalFeedbacks || isDisabledField
                  }
                />
                {visibility === EXTERNAL_FEEDBACK && (
                  <div className='feedback_create__visibility_help'>
                    {/* @ts-ignore */}
                    <CustomIcon iconName='info-icon' />
                    External Feedback will be visible outside of your Office
                  </div>
                )}
              </Grid>
              <Grid item>
                <TasksList
                  tasks={tasks}
                  setTasks={setTasks}
                  visibility={visibility}
                />
              </Grid>
            </div>
          </Grid>
          {!!checklist && (
            <Grid item xs={4} className='feedback_create__checklist'>
              <Checklist
                checklist={checklist}
                onChange={handleChangeChecklist}
                {...(checklist.name === INTERVIEW_CHECKLIST && {
                  maxScale: 2,
                  checklistValidation,
                })}
              />
            </Grid>
          )}
        </Grid>
      </Scrollbars>
    </div>
  );
};
