import React, { CSSProperties } from 'react';
import { useAnchor } from 'react-charts/types/hooks/useAnchor';
import { Axis, Datum, ResolvedChartOptions } from 'react-charts';
import { getPercentages } from '../utils';
import { sum } from 'd3-array';

const showCount = 20;

const getBackgroundColor = (dark?: boolean) =>
  dark ? 'rgba(255,255,255,.9)' : 'rgba(0, 26, 39, 0.9)';

export type TooltipRendererProps<TDatum> = {
  focusedDatum: Datum<TDatum> | null;
  getOptions: () => ResolvedChartOptions<TDatum>;
  primaryAxis: Axis<TDatum>;
  secondaryAxes: Axis<TDatum>[];
  secondaryAxis: Axis<TDatum>;
  getDatumStyle: (datum: Datum<TDatum>) => CSSProperties;
  anchor: ReturnType<typeof useAnchor>;
};

export default function TooltipRenderer<TDatum>(
  props: TooltipRendererProps<TDatum>
) {
  if (!props.focusedDatum) {
    return null;
  }

  const {
    primaryAxis,
    secondaryAxis,
    getDatumStyle,
    focusedDatum,
    secondaryAxes,
  } = props;

  const { tooltip, dark } = props.getOptions();

  const groupDatums = props.focusedDatum?.tooltipGroup ?? [];
  const locationsWithEmployees = groupDatums.filter(
    ({ secondaryValue }) => secondaryValue >= 0
  );

  const resolvedShowCount = showCount % 2 === 0 ? showCount : showCount + 1;
  const length = locationsWithEmployees.length;
  // Get the focused series' index
  const activeIndex = locationsWithEmployees.findIndex(
    (d) => d === focusedDatum
  );

  // Get the start by going back half of the showCount
  let start = activeIndex > -1 ? activeIndex - resolvedShowCount / 2 : 0;
  // Make sure it's at least 0
  start = Math.max(start, 0);
  // Use the start and add the showCount to get the end
  let end = activeIndex > -1 ? start + resolvedShowCount : length;
  // Don't let the end go passed the length
  end = Math.min(end, length);
  // Double check we aren't clipping the start
  start = Math.max(end - resolvedShowCount, 0);
  // Slice the datums by start and end
  const visibleSortedGroupDatums = locationsWithEmployees.slice(start, end);
  // Detect if we have previous items
  const hasPrevious = start > 0;

  return (
    <div
      style={{
        position: 'relative',
        fontSize: '10px',
        padding: '5px',
        background: getBackgroundColor(dark),
        color: dark ? 'black' : 'white',
        borderRadius: '3px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
        }}
      />
      <div>
        <div
          style={{
            marginBottom: '3px',
            textAlign: 'center',
          }}
        >
          {tooltip.groupingMode === 'series' ? (
            <strong>{focusedDatum.seriesLabel}</strong>
          ) : tooltip.groupingMode === 'secondary' ? (
            <strong>
              {(secondaryAxis as any).formatters.tooltip(
                focusedDatum.secondaryValue
              )}
            </strong>
          ) : (
            <strong>
              {(primaryAxis as any).formatters.tooltip(
                focusedDatum.primaryValue
              )}
            </strong>
          )}
        </div>
        <table
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <tbody>
            {hasPrevious ? (
              <tr
                style={{
                  opacity: 0.8,
                }}
              >
                <td />
                <td>...</td>
                <td />
              </tr>
            ) : null}
            {visibleSortedGroupDatums.map((sortedDatum, i) => {
              const active = sortedDatum === focusedDatum;
              const datumSecondaryAxis = secondaryAxes.find(
                (d) => d.id === sortedDatum.secondaryAxisId
              );
              return (datumSecondaryAxis as any).formatters.tooltip(
                sortedDatum.secondaryValue
              ) >= 0 ? (
                <tr
                  key={i}
                  style={{
                    opacity: active ? 1 : 0.8,
                    fontWeight: active ? 'bold' : undefined,
                  }}
                >
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <svg width='14' height='14'>
                      <circle
                        cx='7'
                        cy='7'
                        r='5'
                        style={{
                          ...getDatumStyle(sortedDatum),
                          stroke: dark ? 'black' : 'white',
                          strokeWidth: active ? 2 : 1,
                        }}
                      />
                    </svg>
                  </td>
                  {tooltip.groupingMode === 'series' ? (
                    <React.Fragment>
                      <td>
                        {(primaryAxis as any).formatters.tooltip(
                          sortedDatum.primaryValue
                        )}
                        : &nbsp;
                      </td>
                      <td
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {(datumSecondaryAxis as any).formatters.tooltip(
                          sortedDatum.secondaryValue
                        )}
                      </td>
                    </React.Fragment>
                  ) : tooltip.groupingMode === 'secondary' ? (
                    <React.Fragment>
                      <td>{sortedDatum.seriesLabel}: &nbsp;</td>
                      <td
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {(primaryAxis as any).formatters.tooltip(
                          sortedDatum.primaryValue
                        )}
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <td>{sortedDatum.seriesLabel}: &nbsp;</td>
                      <td
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {(datumSecondaryAxis as any).formatters.tooltip(
                          sortedDatum.secondaryValue
                        )}
                        {`   (${getPercentages(
                          sortedDatum.secondaryValue,
                          sum(
                            focusedDatum.tooltipGroup ?? [],
                            (d) => d.secondaryValue
                          )
                        )}) `}
                      </td>
                    </React.Fragment>
                  )}
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
