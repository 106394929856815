import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getPercentages } from '../utils';
import { styled } from '@mui/system';

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

export default function BasicTable({
  rows,
}: {
  rows: {
    date: string;
    Team: {
      [key: string]: number;
    };
    Ideal: {
      [key: string]: number;
    };
    'Total employees': number;
  }[];
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <WithBorder>Total</WithBorder>
            <WithBorder align='center' colSpan={2}>
              Seniors
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Middles
            </WithBorder>{' '}
            <WithBorder align='center' colSpan={2}>
              Juniors
            </WithBorder>
            <WithBorder align='center'>Id. Sn</WithBorder>
            <WithBorder align='center'>Id. Md</WithBorder>
            <WithBorder align='center'>Id. Jn</WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.date}>
              <TableCell component='th' scope='row'>
                {row.date}
              </TableCell>
              <WithBorder component='th' scope='row'>
                {row['Total employees']}
              </WithBorder>
              <WithBorder align='center'>{row.Team.Seniors}</WithBorder>
              <TableCell align='center'>
                {getPercentages(row.Team.Seniors, row['Total employees'])}
              </TableCell>{' '}
              <WithBorder align='center'>{row.Team.Middles}</WithBorder>
              <TableCell align='center'>
                {getPercentages(row.Team.Middles, row['Total employees'])}
              </TableCell>{' '}
              <WithBorder align='center'>{row.Team.Juniors}</WithBorder>
              <TableCell align='center'>
                {getPercentages(row.Team.Juniors, row['Total employees'])}
              </TableCell>
              <WithBorder align='center'>{row.Ideal.Seniors}</WithBorder>
              <WithBorder align='center'>{row.Ideal.Middles}</WithBorder>
              <WithBorder align='center'>{row.Ideal.Juniors}</WithBorder>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
