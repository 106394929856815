import {
  GLOBAL_SCOPE,
  HR_ZONE,
  MANAGER_SCOPE,
  OFFICE_SCOPE,
  STAFF_SCOPE,
  SUPERVISOR_SCOPE,
  SUPERVISOR_ZONE,
} from '../const-variable';
import { ScopeType, ZoneType } from './types';
import { hasAnyScope } from './checkAccess';

export const hasAccessToFeedbacks = (scope: ScopeType) =>
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(GLOBAL_SCOPE) ||
  scope.includes(STAFF_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const canCreateFeedback = (zone: ZoneType) =>
  zone.includes(HR_ZONE) || zone.includes(SUPERVISOR_ZONE);

export const canCreateOnlyExternalFeedback = (
  scope: ScopeType,
  zone: ZoneType
) =>
  getCanAddExternalFeedbacks(scope, zone) &&
  !getCanAddInternalFeedbacks(scope, zone);

export const canResolveOrEditFeedback = (zone: ZoneType) =>
  zone.includes(HR_ZONE) || zone.includes(SUPERVISOR_ZONE);

const getCanAddInternalFeedbacks = (scope: ScopeType, zone: ZoneType) =>
  (scope.includes(MANAGER_SCOPE) || scope.includes(OFFICE_SCOPE)) &&
  zone.includes(HR_ZONE);

const getCanAddExternalFeedbacks = (scope: ScopeType, zone: ZoneType) =>
  hasAnyScope(scope) && zone.includes(SUPERVISOR_ZONE);

export const getCanAddInternalAndExternalFeedbacks = (
  scope: ScopeType,
  zone: ZoneType
) =>
  getCanAddInternalFeedbacks(scope, zone) &&
  getCanAddExternalFeedbacks(scope, zone);

export const canMarkAsNonActual = (scope: ScopeType) =>
  scope.includes(OFFICE_SCOPE);
