import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { CustomFormFieldset } from './CustomFormFieldset';
import { ReactNode, RefObject, useEffect } from 'react';
import {
  INTERVIEW_CHECKLIST,
  INTERVIEW_FEEDBACK,
} from '../../EmployeesPage/components/EmployeeFeedbacks/consts';

interface Props {
  children: ReactNode;
  onSubmit: () => void;
  initialValues: object;
  validationSchema: object;
  validateOnBlur?: boolean;
  formClassName: string;
  formRef: RefObject<any>;
  onTouchForm: () => void;
  handleTemplateChange?: Function;
  setVisibility?: Function;
  setIsDisabledField?: Function;
  autocompleteTemplateId?: number;
  setIsInterviewFeedbackType?: Function;
  isInterviewFeedbackType?: boolean;
}
const FormContent = (formProps: any) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
    errors,
    setFieldTouched,
    formClassName,
    handleTemplateChange,
    setVisibility,
    setIsDisabledField,
    autocompleteTemplateId,
    setIsInterviewFeedbackType,
    onTouchForm,
    children,
    isInterviewFeedbackType,
  } = formProps;

  // if  feedback_type is selected 'Interview Feedback', the feedback_template must always have 'Interview Checkbox' and visibility - external
  useEffect(() => {
    if (values?.feedback_type?.label === INTERVIEW_FEEDBACK) {
      setFieldValue('checklist', autocompleteTemplateId);
      handleTemplateChange({
        label: INTERVIEW_CHECKLIST,
        value: autocompleteTemplateId,
      });
      setVisibility('external');
      setIsDisabledField(true);
    } else {
      setIsInterviewFeedbackType(false);
      setIsDisabledField(false);
      handleTemplateChange(values?.checklist);
    }
    setFieldTouched('checklist', true, false);
  }, [isInterviewFeedbackType, values]);

  return (
    <Form className={formClassName}>
      <Box pt={3} pb={3}>
        <CustomFormFieldset
          formProps={{
            values,
            setFieldValue,
            isSubmitting,
            errors,
            setFieldTouched,
            setIsInterviewFeedbackType,
          }}
          onTouchForm={onTouchForm}
        >
          {children}
        </CustomFormFieldset>
      </Box>
    </Form>
  );
};
export const CustomForm = (props: Props) => {
  const {
    children,
    onSubmit,
    initialValues,
    validationSchema,
    validateOnBlur,
    formClassName,
    formRef,
    onTouchForm,
    handleTemplateChange,
    setVisibility,
    setIsDisabledField,
    autocompleteTemplateId,
    setIsInterviewFeedbackType,
    isInterviewFeedbackType,
  } = props;

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues || {}}
      enableReinitialize={true}
      validateOnMount={false}
      validateOnChange={!!validationSchema}
      validateOnBlur={!!validationSchema && validateOnBlur}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (onSubmit) {
          // @ts-ignore
          onSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {(formProps) => (
        <FormContent
          {...formProps}
          {...{
            children,
            formClassName,
            onTouchForm,
            handleTemplateChange,
            setVisibility,
            setIsDisabledField,
            autocompleteTemplateId,
            setIsInterviewFeedbackType,
            isInterviewFeedbackType,
          }}
        />
      )}
    </Formik>
  );
};

CustomForm.defaultProps = {
  onSubmit: null,
  initialValues: {},
  validationSchema: undefined,
  validateOnBlur: true,
};
