export const SENIORITIES = [
  { id: 'key_persons', name: 'Key persons' },
  { id: 'lead', name: 'Leads' },
  { id: 'senior', name: 'Seniors' },
  { id: 'middle', name: 'Middles' },
  { id: 'junior', name: 'Juniors' },
];

export const PERIODS = [
  { id: '3', name: 3 },
  { id: '6', name: 6 },
  { id: '12', name: 12 },
];

export const STATUS = [
  { id: 'prod', name: 'Production' },
  { id: 'non-prod', name: 'Non Production' },
  { id: 'bench', name: 'Bench' },
  { id: 'non-bench', name: 'Non Bench' },
];

export const TAGS = { bench_tags: [], not_bench_tags: [] };
